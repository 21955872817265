import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ChangelogComponent } from './changelog.component'
import { IonicModule } from '@ionic/angular'
import { FormsModule } from '@angular/forms'

@NgModule({
  declarations: [ChangelogComponent],
  imports: [CommonModule, IonicModule, FormsModule],
  exports: [ChangelogComponent],
})
export class ChangelogModule {}
