import { Injectable, ViewChild } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

//Providers
import { GlobalController } from 'src/app/controllers/global.controller'

//Controllers
import { StorageController } from './../controllers/storage.controller'

import { serverAddresses } from './server.provider'

@Injectable({
  providedIn: 'root',
})
export class AuthProvider {
  public token: any = ''
  public user: any = null
  public isAuthenticated = false
  server = serverAddresses.api

  constructor(
    public http: HttpClient,
    private globalCtrl: GlobalController,
    private storageCtrl: StorageController
  ) {}

  async initAuth() {
    return Promise.all([this.getUser(), this.getToken()])
  }

  checkAuthentication() {
    return new Promise((resolve, reject) => {
      this.storageCtrl.get('token').then(
        token => {
          this.token = token
          resolve(token)
        },
        error => reject(error)
      )
    })
  }

  setToken(token) {
    this.token = 'Bearer ' + token
    this.storageCtrl.set('token', this.token)
  }

  async setUser(user) {
    this.user = user
    await this.storageCtrl.set('user', this.user)
  }

  private getUser() {
    return new Promise((resolve, reject) => {
      if (this.user) {
        resolve(this.user)
      } else {
        this.storageCtrl
          .get('user')
          .then(user => {
            if (user) {
              this.user = user
              resolve(this.user)
            } else {
              resolve(null)
            }
          })
          .catch(err => {
            console.error(err)
            resolve(null)
          })
      }
    })
  }

  private getToken() {
    return new Promise((resolve, reject) => {
      if (this.token) {
        resolve(this.token)
      } else {
        this.storageCtrl
          .get('token')
          .then(token => {
            if (token) {
              this.token = token
              resolve(this.token)
            } else {
              resolve(null)
            }
          })
          .catch(err => {
            console.error(err)
            resolve(null)
          })
      }
    })
  }

  login(credentials) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('app-version', this.globalCtrl.appVersion.number)
      this.http
        .post(this.server + '/login', credentials, {
          headers: headers,
          withCredentials: true,
        })
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            console.error(err)
            reject(err)
          }
        )
    })
  }

  logout() {
    this.token = null
    this.user = null
    this.setToken(null)
    this.setUser(null)
    this.storageCtrl.clear()
    const headers = new HttpHeaders().set('app-version', this.globalCtrl.appVersion.number)
    // logout in the API deletes jwt cookies.
    this.http.get(this.server + '/logout', { headers: headers, withCredentials: true }).subscribe()
  }

  getUserRole() {
    return this.user && this.user.role ? this.user.role : 'employee'
  }

  getUserId() {
    return this.user && this.user._id ? this.user._id : null
  }

  getUserFirstName() {
    return this.user && this.user.firstName ? this.user.firstName : null
  }

  getUserFullName() {
    return this.user && this.user.firstName && this.user.lastName
      ? this.user.firstName + ' ' + this.user.lastName
      : null
  }

  getUserLanguage() {
    return this.user && this.user.language ? this.user.language : 'fr'
  }

  changePassword(newPassword, token) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders().set('app-version', this.globalCtrl.appVersion.number)
      headers = headers.append('Authorization', 'Bearer ' + token)
      this.http
        .put(this.server + '/forgot-password', newPassword, {
          headers: headers,
          withCredentials: true,
        })
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            console.error(err)
            reject(err)
          }
        )
    })
  }

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('app-version', this.globalCtrl.appVersion.number)
      this.http.post(this.server + '/forgot-password', email, { headers: headers }).subscribe(
        (res: any) => {
          resolve(res)
        },
        err => {
          console.error(err)
          reject(err)
        }
      )
    })
  }
}
