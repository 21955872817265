<ion-header>
  <ion-toolbar>
    <ion-button (click)="dismiss()" slot="start" fill="clear" data-cy="DismissChangelogButton">
      <ion-icon name="arrow-back-outline"></ion-icon>
    </ion-button>

    <ion-title>Notes des versions</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="changelogInfo">
    <ion-list>
      <ion-text *ngFor="let release of releases" class="changelogElement">
        <ion-label>
          <h1>{{ release.tag_name }}:</h1>
          <p *ngFor="let line of release.body">{{ line }}</p>
        </ion-label>
      </ion-text>
    </ion-list>
  </div>
</ion-content>
