import { Injectable } from '@angular/core'
import { Router, CanActivate, UrlTree, ActivatedRouteSnapshot } from '@angular/router'

//Providers
import { AuthProvider } from '../providers/auth.provider'

//Controllers
import { StorageController } from './storage.controller'
import { GlobalController } from './global.controller'
import { LogoutService } from '../services/logout.service'

@Injectable({
  providedIn: 'root',
})
export class AuthGuardController {
  constructor(
    public authService: AuthProvider,
    public router: Router,
    public storageCtrl: StorageController,
    public globalCtrl: GlobalController,
    public logoutService: LogoutService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    await this.authService.initAuth()

    const user = await this.storageCtrl.get('user')

    if (!user) {
      return this.logout()
    }

    if (!this.hasAccess(route.data['roles'], user)) {
      return false
    }

    if (this.authService.isAuthenticated) {
      const token: any = await this.storageCtrl.get('token')
      if (!token || this.tokenExpired(token)) {
        return this.logout()
      }
      return true
    }

    if (this.authService.token) {
      if (this.tokenExpired(this.authService.token)) {
        return this.logout()
      }
      this.authService.isAuthenticated = true
      return true
    }

    const token: any = await this.storageCtrl.get('token')
    if (!token) {
      return this.logout()
    } else {
      if (this.tokenExpired(token)) {
        return this.logout()
      }
    }

    this.authService.token = token
    this.authService.user = user
    this.authService.isAuthenticated = true //!this.jwtHelper.isTokenExpired(token);
    this.globalCtrl.events.emit('user:login')

    return true
  }

  hasAccess(roles, user): boolean {
    if (roles) {
      if (roles.includes(user.role)) {
        return true
      } else {
        switch (user.role) {
          case 'admin':
            this.router.navigate(['/overview?1'])
            break
          case 'employee':
            this.router.navigate(['/time-entries'])
            break
          case 'supervisor':
            this.router.navigate(['/dashboard-supervisor'])
            break
        }
      }
    }
    return false
  }

  tokenExpired(token: string): boolean {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp
    return Math.floor(new Date().getTime() / 1000) >= expiry
  }

  logout() {
    this.logoutService.logoutUser()
    const url = 'login'
    const tree: UrlTree = this.router.parseUrl(url)
    return tree
  }
}
