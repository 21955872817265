import { Injectable } from '@angular/core'

//Controllers
import { GlobalController } from './global.controller'
//Ionic Native
import { Storage } from '@ionic/storage'

@Injectable({
  providedIn: 'root',
})
export class StorageController {
  _storage: any

  constructor(
    private storage: Storage,
    private globalCtrl: GlobalController
  ) {
    this.init()
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create()
    this._storage = storage
    this.globalCtrl.events.emit('storage_created')
  }

  isInitialized() {
    return new Promise((resolve, reject) => {
      const time = 500
      // TEMPORARY
      setTimeout(() => {
        resolve(true)
      }, time)
    })
  }

  set(itemName, item) {
    return new Promise((resolve, reject) => {
      this._storage.set(itemName, item).then(
        () => {
          resolve(true)
        },
        err => {
          reject(err)
        }
      )
    })
  }

  get(itemName) {
    return new Promise((resolve, reject) => {
      if (this._storage == undefined) {
        this.globalCtrl.events.once('storage_created', () => {
          this._get(itemName).then(
            res => resolve(res),
            err => reject(err)
          )
        })
      } else {
        this._get(itemName).then(
          res => resolve(res),
          err => reject(err)
        )
      }
    })
  }

  _get(itemName: string) {
    return new Promise((resolve, reject) => {
      this._storage.get(itemName).then(val => {
        resolve(val)
      })
    })
  }

  clear() {
    return new Promise((resolve, reject) => {
      this._storage.clear().then(
        (nothing: any) => {
          resolve(nothing)
        },
        err => {
          reject(err)
        }
      )
    })
  }
}
