import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { RouteReuseStrategy } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { IonicStorageModule } from '@ionic/storage-angular'

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io'
import { environment } from 'src/environments/environment'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ChangelogModule } from './components/changelog/changelog.module'
import { PhoneFormatDirective } from './directives/phone-format.directive'
import { TranslocoRootModule } from './transloco-root.module'

const socket_config: SocketIoConfig = { url: environment.API_URL, options: {} }

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    SocketIoModule.forRoot(socket_config),
    ChangelogModule,
    PhoneFormatDirective,
    TranslocoRootModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
