import { Injectable } from '@angular/core'
import { ToastController, Platform, LoadingController, AlertController } from '@ionic/angular'
//Modules
import { DateTime } from 'luxon'
import { Event } from 'event.es6'
import { environment } from 'src/environments/environment'
import { TranslocoService } from '@jsverse/transloco'

@Injectable({
  providedIn: 'root',
})
export class GlobalController {
  devMode = false
  production = environment.production
  events = new Event()
  language: string = null

  networkConnected = true
  platformDevice: any
  appVersion = {
    string: '0.0.1',
    number: '00001',
  }
  noInternetToast: any

  colors = {
    green: '#a6ff13',
    green_20: '#a6ff1333',
  }

  priorityLabels = [
    { value: 1, label: 'High', color: '#85201b' },
    { value: 2, label: 'Medium', color: '#f5af23' },
    { value: 3, label: 'Low', color: '#1b8520' },
  ]

  constructor(
    private toastCtrl: ToastController,
    private platform: Platform,
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
    private translocoService: TranslocoService
  ) {
    this.language = this.translocoService.getActiveLang()
    this.platform.ready().then(() => {
      this.platformDevice = this.platform.is('mobile') ? 'mobile' : 'desktop'
    })
  }

  switchLanguage() {
    if (this.language == 'en') {
      this.translocoService.setActiveLang('fr')
    } else {
      this.translocoService.setActiveLang('en')
    }
    this.language = this.translocoService.getActiveLang()
  }

  getSwitchLanguage() {
    if (this.language == 'en') {
      return 'FR'
    } else {
      return 'EN'
    }
  }

  getLanguage() {
    return this.language
  }

  setLanguage(lang: string) {
    this.language = lang
    this.translocoService.setActiveLang(lang)
  }

  checkInternetConnection(): boolean {
    if (this.networkConnected) return true
    else {
      this.showErrorToast('Aucune connection internet', 0, true)
      return false
    }
  }

  async showNoConnectionToast() {
    let noInternetMessage, closeText
    this.noInternetToast = await this.toastCtrl.create({
      message: noInternetMessage,
      duration: 3000,
      position: 'top',
      buttons: [
        {
          text: closeText,
          role: 'cancel',
          handler: () => {},
        },
      ],
    })

    await this.noInternetToast.present()
  }

  //Show message
  async showErrorToast(message: string, duration = 3000, showCloseButton = true) {
    let buttons = []
    if (showCloseButton) {
      buttons.push({
        text: 'Fermer',
        role: 'cancel',
        handler: () => {},
      })
    }
    let toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      position: 'bottom',
      cssClass: 'errorToast',
      buttons: buttons,
    })
    await toast.present()
  }

  async showConfToast(message: string, duration = 3000, showCloseButton = true) {
    let buttons = []
    if (showCloseButton) {
      buttons.push({
        text: 'Fermer',
        role: 'cancel',
        handler: () => {},
      })
    }
    let toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      position: 'bottom',
      cssClass: 'confToast',
      buttons: buttons,
    })

    await toast.present()
  }

  async showWarningToast(message: string, duration = 3000, showCloseButton = true) {
    let buttons = []
    if (showCloseButton) {
      buttons.push({
        text: 'Fermer',
        role: 'cancel',
        handler: () => {},
      })
    }
    let toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      position: 'bottom',
      cssClass: 'warningToast',
      buttons: buttons,
    })

    await toast.present()
  }

  loadExternalScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script')
      scriptElement.src = scriptUrl
      scriptElement.onload = resolve
      scriptElement.onerror = reject
      document.body.appendChild(scriptElement)
    })
  }

  sortByDate(items, field = null, reverseOrder = true) {
    return items.sort((a, b) => {
      let ATime, BTime

      const convertToMilliSeconds = date => {
        if (date instanceof Date) {
          return DateTime.fromJSDate(date, { zone: 'utc' }).toMillis()
        } else if (typeof date === 'string') {
          return DateTime.fromISO(date, { zone: 'utc' }).toMillis()
        } else if (typeof date === 'number') {
          return DateTime.fromMillis(date, { zone: 'utc' }).toMillis()
        } else {
          return 0
        }
      }
      if (field) {
        ATime = convertToMilliSeconds(a[field])
        BTime = convertToMilliSeconds(b[field])
      } else {
        ATime = convertToMilliSeconds(a)
        BTime = convertToMilliSeconds(b)
      }

      return reverseOrder ? BTime - ATime : ATime - BTime
    })
  }

  formatDate(date) {
    return DateTime.fromJSDate(date, { zone: 'utc' })
      .setLocale('fr')
      .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })
  }

  reverseObj(obj) {
    return Object.keys(obj)
      .reverse()
      .reduce((a, key, i) => {
        a[key] = obj[key]
        return a
      }, {})
  }

  async showLoading(message, duration = 0) {
    const loading = await this.loadingCtrl.create({
      message: message,
      duration: duration,
    })

    loading.present()

    return loading
  }

  getPriorityLabels() {
    return this.priorityLabels
  }

  getPlatform() {
    return this.platformDevice
  }

  makeErrorMessage(error) {
    switch (error.status) {
      case 500:
        return "Une erreur est survenu. Si le problème persiste, veuillez contacter l'administrateur du système"
      case 401:
        return "Vous n'êtes pas autorisé à effectuer cette action"
      case 409:
        return 'Conflit: Un élément avec les mêmes caractéristiques existe déjà'
      case 400:
        return 'Une mauvaise requête a été envoyée au serveur. Veuillez réessayer plus tard'
      default:
        return 'Une erreur inattendue est survenue'
    }
  }

  showErrorMessage(error) {
    let errorMessage = this.makeErrorMessage(error)
    this.showErrorToast(errorMessage)
    console.error(error.message)
  }

  async presentAlert(header: string, message: string, buttons: Array<any>, subHeader?: string) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: buttons,
    })

    await alert.present()
  }

  toggleMenu() {
    const splitPane = document.querySelector('ion-split-pane')
    splitPane.when = !splitPane.when
  }

  async hideMenu(): Promise<void> {
    const splitPane = document.querySelector('ion-split-pane')
    splitPane.when = false
  }
}
