import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'
import { AuthProvider } from '../providers/auth.provider'
import { GlobalController } from '../controllers/global.controller'

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(
    private authService: AuthProvider,
    private globalCtrl: GlobalController
  ) {}

  public getCommonHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.authService.token,
      'app-version': this.globalCtrl.appVersion.number,
    })
  }
}
