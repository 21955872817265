import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { serverAddresses } from './server.provider'
import { AuthProvider } from './auth.provider'
import { HeaderService } from '../services/header.service'

@Injectable({
  providedIn: 'root',
})
export class AlertProvider {
  server = serverAddresses.api

  constructor(
    public http: HttpClient,
    public authService: AuthProvider,
    private headerService: HeaderService
  ) {}

  getAlerts(days: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let url = this.server + '/alerts?days=' + days

      this.http.get(url, { headers: this.headerService.getCommonHeaders() }).subscribe(
        (res: any[]) => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    })
  }

  getAlertsNotifications(days: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let url = this.server + '/alerts/notifications?days=' + days

      this.http.get(url, { headers: this.headerService.getCommonHeaders() }).subscribe(
        (res: any[]) => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    })
  }
}
