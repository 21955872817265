import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class DeviceController {
  cordovaMode = false
  isRealCordova = false

  isCordova = true
  isMobileBrowser = false
  isDesktop = false
  isNative = true
  useNativeStorage = true

  isIOS = false

  desktopView = false

  desktopViewBreakpoint = 1080

  constructor(private platform: Platform) {
    this.platform.ready().then(() => {
      this.useNativeStorage = this.platform.is('cordova')

      if (!this.cordovaMode) {
        this.isCordova = this.platform.is('cordova')
        this.isDesktop = this.platform.is('desktop')
        this.isIOS = this.platform.is('ios')
        this.isNative = this.platform.is('ios') || this.platform.is('android')
        this.isMobileBrowser = this.platform.is('mobileweb')
        this.desktopView = this.platform.width() >= this.desktopViewBreakpoint

        this.platform.resize.subscribe(() => {
          this.desktopView = this.platform.width() >= this.desktopViewBreakpoint
        })
      }

      this.isRealCordova = this.platform.is('cordova')
    })
  }
}
