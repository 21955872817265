import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent {
  releases = []
  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    })
  }
}
